
import Vue from 'vue'
import App from './App.vue'
import router from './route/index'
//富文本1
import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);




Vue.config.productionTip = false
import axios from "axios"
axios.defaults.baseURL="api"
Vue.prototype.$http = axios

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
